<template>
  <v-text-field
    v-bind="$attrs"
    ref="field"
    :rules="getRules"
    outlined
    :background-color="backgroundColor"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "TTextInput",

  props: {
    backgroundColor: {
      type: String,
      default: "white",
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredErrorMessage: {
      type: String,
      default: "Este campo é obrigatório",
    },
    rules: {
      type: Array as PropType<((v: string | number) => boolean | string)[] | []>,
      default: () => [],
    },
  },

  computed: {
    getRules(): ((v: string | number) => boolean | string)[] | [] {
      const required = this.required ? [ (v: string | number) => !!(v.toString().trim()) || this.requiredErrorMessage ] : [];

      return [...required, ...this.rules];
    },
  },

  methods: {
    validate(): boolean {
      const field = this.$refs.field as Vue & { validate(raise: boolean): boolean };
      return field.validate(true);
    },
  },
});
</script>
